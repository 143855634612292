import {MapTo} from '@matchsource/api-utils';
import {EthnicityRacesModel} from '@matchsource/models/source';
import {EthnicityRaceInfo} from '@matchsource/api-generated/subject';

export const mapEthnicityRaceDtoToModel: MapTo<EthnicityRaceInfo, EthnicityRacesModel> = (
  input: EthnicityRaceInfo
) => ({
  ethnicityCode: input.ethnicityCode,
  primaryRaceCode: input.primaryRaceCode,
  raceCodes: input.raceCodes,
});
